<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.localization.city')}}</h4>
            <div class="form-group">
              <select v-model="selectedCity" class="form-control form-control-sm">
                <option v-for="item in cities" :key="item.id" :value="item.id">
                  {{item.city_name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6"><h4 class="card-title">{{$t('views.metro_stations.metro_lines')}}</h4></div>
              <div class="col-lg-6 text-right">
                <div @click="showAddLinePopup" class="btn btn-sm btn-success">
                  <i class="fa fa-plus"></i>
                  {{ $t('views.metro_stations.metro_lines') }}
                </div>
              </div>
            </div>

            <div v-show="metroLines.length > 0" class="row mt-3">
              <div class="col-12">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>{{$t('views.metro_stations.line_code')}}</th>
                      <th>{{$t('views.metro_stations.line_name')}}</th>
                      <th>{{$t('views.metro_stations.line_color')}}</th>
                      <th class="text-right">{{$t('popularWords.actions')}}</th>
                    </tr>
                  </thead>
                  <tbody class="mt-3" v-for="(item, index) in metroLines" :key="index">
                  <tr>
                    <td>{{item.line_code}}</td>
                    <td>{{item.line_name}}</td>
                    <td>
                      <div :style="'border-bottom: 3px solid #' +item.line_color">#{{item.line_color}}</div>
                      <div></div>
                    </td>
                    <td class="text-right">
                      <div class="button-items">
                        <div @click="showAddStationPopup(item.id)" class="btn btn-sm btn-success">
                          <i class="fa fa-plus"></i>
                          {{ $t('views.metro_stations.station') }}
                        </div>
                        <div @click="showDeleteLinePopup(item.id)" class="btn btn-sm btn-danger">{{$t('popularWords.delete')}}</div>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="station in item.metroStations" :key="station.id">
                    <td></td>
                    <td class="station-row">- {{ station.station_name }}</td>
                    <td></td>
                    <td class="text-right">
                      <div @click="showDeleteStationPopup(station.id)" class="btn btn-sm btn-danger">{{$t('popularWords.delete')}}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Добавление линии метро -->
    <popup
      :closeButton="addLinePopup.closeButton"
      :actionButton="addLinePopup.actionButton"
      :actionClass="addLinePopup.actionClass"
      :show="addLinePopup.show"
      @closePopup="closeAddLinePopup"
      @actionPopup="addLinePopupSubmit"
    >
      <div slot="header">{{$t('views.metro_stations.new_line')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newLine_lineCode">{{ $t('views.metro_stations.line_code') }}</label>
            <input
                id="newLine_lineCode"
                v-model="newLine.line_code"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newLine.line_code.$error }"
            />
            <div v-if="submitted && $v.newLine.line_code.$error" class="invalid-feedback">
              <span v-if="!$v.newLine.line_code.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newLine.line_code.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newLine.line_code.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 10
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="newLine_lineName">{{ $t('views.metro_stations.line_name') }}</label>
            <input
                id="newLine_lineName"
                v-model="newLine.line_name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newLine.line_name.$error }"
            />
            <div v-if="submitted && $v.newLine.line_name.$error" class="invalid-feedback">
              <span v-if="!$v.newLine.line_name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newLine.line_name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newLine.line_name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 255
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="newLine_lineColor">{{ $t('views.metro_stations.line_color') }}</label>
            <input
                id="newLine_lineColor"
                v-model="newLine.line_color"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newLine.line_color.$error }"
            />
            <small class="form-text text-muted">
              Задается в шестнадцатеричной системе. Пример: #556EE6
            </small>
            <div v-if="submitted && $v.newLine.line_color.$error" class="invalid-feedback">
              <span v-if="!$v.newLine.line_color.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newLine.line_color.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newLine.line_color.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 7
              </span>
            </div>
          </div>
        </form>
      </div>
    </popup>

    <!-- Добавление станции метро -->
    <popup
      :closeButton="addStationPopup.closeButton"
      :actionButton="addStationPopup.actionButton"
      :actionClass="addStationPopup.actionClass"
      :show="addStationPopup.show"
      @closePopup="closeAddStationPopup"
      @actionPopup="addStationPopupSubmit"
    >
      <div slot="header">{{$t('views.metro_stations.new_station')}}</div>
      <div slot="body">
        <form class="needs-validation">
          <div class="form-group">
            <label for="newStation_stationName">{{ $t('views.metro_stations.station_name') }}</label>
            <input
                id="newStation_stationName"
                v-model="newStation.station_name"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': submitted && $v.newStation.station_name.$error }"
            />
            <div v-if="submitted && $v.newStation.station_name.$error" class="invalid-feedback">
              <span v-if="!$v.newStation.station_name.required">{{ $t('validatePhrases.requiredField') }}</span>
              <span v-if="!$v.newStation.station_name.minLength">
                {{ $t('validatePhrases.minLength') }} - 1
              </span>
              <span v-if="!$v.newStation.station_name.maxLength">
                {{ $t('validatePhrases.maxLength') }} - 255
              </span>
            </div>
          </div>
        </form>
      </div>
    </popup>

    <!-- Удаление линии метро -->
    <popup
      :closeButton="deleteLinePopup.closeButton"
      :actionButton="deleteLinePopup.actionButton"
      :actionClass="deleteLinePopup.actionClass"
      :show="deleteLinePopup.show"
      @closePopup="closeDeleteLinePopup"
      @actionPopup="deleteLinePopupSubmit"
    >
      <div slot="header">{{$t('views.metro_stations.delete_line')}} ?</div>
      <div slot="body">
        {{$t('views.metro_stations.delete_line_text')}}
      </div>
    </popup>

    <!-- Удаление станции метро -->
    <popup
      :closeButton="deleteStationPopup.closeButton"
      :actionButton="deleteStationPopup.actionButton"
      :actionClass="deleteStationPopup.actionClass"
      :show="deleteStationPopup.show"
      @closePopup="closeDeleteStationPopup"
      @actionPopup="deleteStationPopupSubmit"
    >
      <div slot="header">{{$t('views.metro_stations.delete_station')}} ?</div>
      <div slot="body">
        {{$t('views.metro_stations.delete_station_text')}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from '../../../components/Popup.vue';

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";


export default {
  components: {
    Layout,
    PageHeader,
    Popup
},
  data() {
    return {
      submitted: false,

      cities: [],
      selectedCity: 0,

      metroLines: [],

      // Новая линия
      addLinePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newLine: {
        line_code: '',
        line_name: '',
        line_color: ''
      },

      // Новая станция
      addStationPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      newStation: {
        line_id: '',
        station_name: '',
      },

      deleteLinePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedLineId: 0,

      deleteStationPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      deletedStationId: 0,

      title: this.$t('menuitems.management.list.metro_stations'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.metro_stations'),
          active: true
        }
      ],
    };
  },
  validations: {
    newLine: {
      line_code: { required, minLength: minLength(1), maxLength: maxLength(10) },
      line_name: { required, minLength: minLength(1), maxLength: maxLength(255) },
      line_color: { required, minLength: minLength(1), maxLength: maxLength(7) },
    },
    newStation: {
      station_name: { required, minLength: minLength(1), maxLength: maxLength(255) }
    }
  },
  computed: {

  },
  watch: {
    selectedCity: function(val){
      this.getMetroLines(val);
    }
  },
  methods: {
    showAddStationPopup(line_id){
      this.newStation.line_id = line_id;
      this.addStationPopup.show = true;
    },
    closeAddStationPopup(){
      this.addStationPopup.show = false;
    },
    addStationPopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newStation.$touch();
      if(!this.$v.newStation.$invalid){
        let formData = new FormData();
        formData.append('line_id', this.newStation.line_id);
        formData.append('station_name', this.newStation.station_name);

        axios
            .post(`/v1/localization/add-metro-station`, formData)
            .then(() => {
              this.closeAddStationPopup();
              this.getMetroLines(this.selectedCity);
              this.submitted = false;
              this.newStation.line_id = '';
              this.newStation.station_name = '';

              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },

    showDeleteStationPopup(station_id){
      this.deletedStationId = station_id;
      this.deleteStationPopup.show = true;
    },
    closeDeleteStationPopup(){
      this.deleteStationPopup.show = false;
    },
    deleteStationPopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedStationId);

      axios
          .post(`/v1/localization/delete-metro-station`, formData)
          .then(() => {
            this.closeDeleteStationPopup();
            this.getMetroLines(this.selectedCity);

            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showDeleteLinePopup(lineId){
      this.deletedLineId = lineId;
      this.deleteLinePopup.show = true;
    },
    closeDeleteLinePopup(){
      this.deleteLinePopup.show = false;
    },
    deleteLinePopupSubmit(){
      let formData = new FormData();
      formData.append('id', this.deletedLineId);

      axios
          .post(`/v1/localization/delete-metro-line`, formData)
          .then(() => {
            this.closeDeleteLinePopup();
            this.getMetroLines(this.selectedCity);

            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

    showAddLinePopup(){
      if(this.selectedCity === 0){
        this.$store.dispatch("addNotification", {
          text: this.$t('views.metro_stations.no_select_city'),
          time: 3,
          color: "danger"
        });
        return false;
      }
      this.addLinePopup.show = true;
    },
    closeAddLinePopup(){
      this.addLinePopup.show = false;
    },
    addLinePopupSubmit(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newLine.$touch();
      if(!this.$v.newLine.$invalid){
        let formData = new FormData();
        formData.append('city_id', this.selectedCity);
        formData.append('line_code', this.newLine.line_code);
        formData.append('line_name', this.newLine.line_name);
        formData.append('line_color', this.newLine.line_color);

        axios
            .post(`/v1/localization/add-metro-line`, formData)
            .then(() => {
              this.closeAddLinePopup();
              this.getMetroLines(this.selectedCity);
              this.submitted = false;
              this.newLine.line_code = '';
              this.newLine.line_name = '';
              this.newLine.line_color = '';

              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    getMetroLines(city_id){
      let formData = new FormData();
      formData.append('city_id', city_id);

      axios
          .post(`/v1/localization/get-metro-lines`, formData)
          .then(resp => {
            this.metroLines = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCities(){
      axios
          .get(`/v1/localization/get-city`)
          .then(resp => {
            this.cities = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getCities();
  }
};
</script>

<style scoped lang="scss">
  .station-row{
    color: gray;
  }
</style>